import Vue from 'vue'
import App from './App.vue'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'

Vue.config.productionTip = false
Vue.prototype.$Toast = MintUI.Toast 
new Vue({
  render: h => h(App),
}).$mount('#app')
